import { BrowserHistory } from 'history';
import 'moment/locale/nl';
import * as React from 'react';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import Types from 'Types';
import { useLocation } from 'wouter';
import AppRouter from './AppRouter';
import CustomContextProvider from './components/CustomContextProvicer/index';
import ReplaceStateDialog from './components/ReplaceStateDialog';
import msgs from './locales';
import useBarcodeScanner from './services/BarcodeScanner/useBarcodeScanner';
import { CountingRequestStoreProvider } from './stores/countingRequestStore';
import { CountingStoreProvider } from './stores/countingStore';
import { MaterialListStoreProvider } from './stores/materialListStore';

function matchPath(path: string) {
  const regex = /^\/qr\/location\/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})$/i;
  const match = path.match(regex);
  return match ? match[1] : null;
}

const GlobalBarodeScannerBehaviour: React.FC<any> = ({ children }) => {
  const [, navigate] = useLocation();
  const handleScan = React.useCallback((barcodeString: string) => {
    try {
      const url = new URL(barcodeString);
      console.log(url);
      const currentHost = window.location.host;

      const uuid = matchPath(url.pathname);

      if (!uuid && url.host !== currentHost) {
        throw new Error('Unknown QR');
      }

      if (!uuid) {
        throw new Error('Unknown QR: URL path wrong');
      }

      if (url.host !== currentHost) {
        throw new Error(
          'Unknown QR; Host does not match; is this a different environment?',
        );
      }

      navigate(`/inventory/${uuid}`);
    } catch (error) {
      return window.alert((error as Error).message);
    }
  }, []);

  useBarcodeScanner(handleScan, 0);

  return children || null;
};

const locale = navigator?.language?.slice(0, 2).toLowerCase() || 'en';

export interface RootProps {
  store: Store<Types.RootState>;
  history: BrowserHistory;
}

const Root: React.FC<RootProps> = ({ store, history }) => {
  return (
    <Provider store={store}>
      <CountingStoreProvider>
        <CountingRequestStoreProvider>
          <MaterialListStoreProvider>
            <IntlProvider
              defaultLocale="nl"
              locale={locale}
              messages={(msgs as any)[locale]}
              onError={() => {}}
              onWarn={() => {}}
            >
              <CustomContextProvider>
                <AppRouter history={history}>
                  <GlobalBarodeScannerBehaviour />
                </AppRouter>
                <ReplaceStateDialog />
                {/* some modal components find #modal and mount a react portal in it */}
                <div id="modal"></div>
              </CustomContextProvider>
            </IntlProvider>
          </MaterialListStoreProvider>
        </CountingRequestStoreProvider>
      </CountingStoreProvider>
    </Provider>
  );
};

export default Root;
