import { defineMessages } from 'react-intl';

export default defineMessages({
  FROM_PROJECT: {
    id: 'INVENTORY_ORDER.TYPE_FROM_PROJECT',
    defaultMessage: 'From Project',
  },
  TO_PROJECT: {
    id: 'INVENTORY_ORDER.TYPE_TO_PROJECT',
    defaultMessage: 'To Project',
  },
  SCRAP: {
    id: 'INVENTORY_ORDER.TYPE_SCRAP',
    defaultMessage: 'Shrinkage',
  },
  FROM_SUPPLIER: {
    id: 'INVENTORY_ORDER.TYPE_FROM_SUPPLIER',
    defaultMessage: 'From Supplier',
  },
  INVENTORY: {
    id: 'INVENTORY_ORDER.TYPE_INVENTORY',
    defaultMessage: 'Inventory',
  },
  FROM_PROJECT_SHORT: {
    id: 'INVENTORY_ORDER.TYPE_FROM_PROJECT_SHORT',
    defaultMessage: 'From Proj.',
  },
  TO_PROJECT_SHORT: {
    id: 'INVENTORY_ORDER.TYPE_TO_PROJECT_SHORT',
    defaultMessage: 'To Proj.',
  },
  SCRAP_SHORT: {
    id: 'INVENTORY_ORDER.TYPE_SCRAP_SHORT',
    defaultMessage: 'Shrink',
  },
  FROM_SUPPLIER_SHORT: {
    id: 'INVENTORY_ORDER.TYPE_FROM_SUPPLIER_SHORT',
    defaultMessage: 'From Suppl.',
  },
  INVENTORY_SHORT: {
    id: 'INVENTORY_ORDER.TYPE_INVENTORY_SHORT',
    defaultMessage: 'Inventory',
  },
  // WORKSHOP: {
  //   id: 'INVENTORY_ORDER.TYPE_WORKSHOP',
  //   defaultMessage: 'Workshop',
  // },
  note: {
    id: 'INVENTORY_ORDER.NOTE_FIELD_LABEL',
    defaultMessage: 'Note',
  },
  notePlaceholder: {
    id: 'INVENTORY_ORDER.NOTE_FIELD_PLACEHOLDER',
    defaultMessage: 'Enter note here',
  },
  notePlaceholderSupplier: {
    id: 'INVENTORY_ORDER.NOTE_FIELD_PLACEHOLDER_SUPPLIER',
    defaultMessage: 'Fill in name of Supplier and possibly other notes',
  },
  NAME: {
    id: 'INVENTORY_ORDER.NAME_FIELD_LABEL',
    defaultMessage: 'Name',
  },
  ORDER_TYPE: {
    id: 'INVENTORY_ORDER.ORDER_TYPE_LABEL',
    defaultMessage: 'Order Type',
  },
  STATUS: {
    id: 'INVENTORY_ORDER.STATUS_LABEL',
    defaultMessage: 'Status',
  },
  DRAFTED: {
    id: 'INVENTORY_ORDER.STATUS_DRAFTED',
    defaultMessage: 'Drafted',
  },
  SUBMITTED: {
    id: 'INVENTORY_ORDER.STATUS_SUBMITTED',
    defaultMessage: 'Submitted',
  },
  COMPLETED: {
    id: 'INVENTORY_ORDER.STATUS_COMPLETED',
    defaultMessage: 'Completed',
  },
  CANCELLED: {
    id: 'INVENTORY_ORDER.STATUS_CANCELLED',
    defaultMessage: 'Cancelled',
  },
  CREATED_BY: {
    id: 'INVENTORY_ORDER.CREATED_BY_LABEL',
    defaultMessage: 'Created by',
  },
  CREATED: {
    id: 'INVENTORY_ORDER.CREATED_LABEL',
    defaultMessage: 'Created',
  },
  SAVE: {
    id: 'INVENTORY_ORDER.SAVE_BUTTON',
    defaultMessage: 'Save Customer Details', // FIXME: although requested, this is not a good name
  },
  SUBMIT_FOR_PROCESSING_BUTTON: {
    id: 'INVENTORY_ORDER.SUBMIT_FOR_PROCESSING_BUTTON',
    defaultMessage: 'Submit for processing',
  },
  REVERT_TO_DRAFT: {
    id: 'INVENTORY_ORDER.REVERT_TO_DRAFT_BUTTON',
    defaultMessage: 'Revert to draft',
  },
  DELETE: {
    id: 'INVENTORY_ORDER.DELETE_BUTTON',
    defaultMessage: 'Delete',
  },
  AMOUNT: {
    id: 'INVENTORY_ORDER.AMOUNT_FIELD_LABEL',
    defaultMessage: 'Qty',
  },
  CODE: {
    id: 'INVENTORY_ORDER.CODE_FIELD_LABEL',
    defaultMessage: 'Code',
  },
  DESCRIPTION: {
    id: 'INVENTORY_ORDER.DESCRIPTION_FIELD_LABEL',
    defaultMessage: 'Description',
  },
  ADD_MATERIAL: {
    id: 'INVENTORY_ORDER.ADD_MATERIAL_BUTTON',
    defaultMessage: 'Add material',
  },
  UNKNOWN_USER: {
    id: 'INVENTORY_ORDER.UNKNOWN_USER',
    defaultMessage: 'Unknown user',
  },
  PROCESSING: {
    id: 'INVENTORY_ORDER.PROCESSING_LABEL',
    defaultMessage: 'Processing',
  },
  DELETE_ITEM: {
    id: 'INVENTORY_ORDER.DELETE_ITEM',
    defaultMessage: 'Delete item',
  },
  CONFIRM_DELETE: {
    id: 'INVENTORY_ORDER.CONFIRM_DELETE',
    defaultMessage: 'Do you want to delete this material list?',
  },
  details: {
    id: 'INVENTORY_ORDER.OVERVIEW_DETAILS_COLUMN_LABEL',
    defaultMessage: 'Details',
  },
  type: {
    id: 'INVENTORY_ORDER.OVERVIEW_DETAILS_COLUMN_TYPE',
    defaultMessage: 'Type',
  },
});
