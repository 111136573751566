import { CALL_API } from 'redux-api-middleware';
import { User } from '../models/User';
import { mapByRecnum, resolveHeaders, resolveUrl } from '../utils';
import { softAssertNever } from './utils';
import Types from 'Types';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

export interface RequestFetchUsersAction {
  type: 'REQUEST_FETCH_USERS';
}
export interface SuccessFetchUsersAction {
  type: 'SUCCESS_FETCH_USERS';
  payload: User[];
}
export interface FailureFetchUsersAction {
  type: 'FAILURE_FETCH_USERS';
  error: true;
}

export function fetchUsers(): any {
  return {
    [CALL_API]: {
      endpoint: resolveUrl('/users'),
      method: 'GET',
      headers: resolveHeaders(),
      types: [
        'REQUEST_FETCH_USERS',
        'SUCCESS_FETCH_USERS',
        'FAILURE_FETCH_USERS',
      ],
    },
  };
}

export type UsersAction =
  | RequestFetchUsersAction
  | SuccessFetchUsersAction
  | FailureFetchUsersAction;

//fixme: why user| string
export type UsersState = Record<number | string, User> | null;

export default function users(
  state: UsersState = null,
  action: UsersAction,
): UsersState {
  if (action.type === 'SUCCESS_FETCH_USERS') {
    return mapByRecnum(action.payload);
  } else if (
    action.type === 'REQUEST_FETCH_USERS' ||
    action.type === 'FAILURE_FETCH_USERS'
  ) {
    return state;
  }
  softAssertNever(action);
  return state;
}

export function useUsersMap() {
  return useSelector<Types.RootState, UsersState>(s => s.users);
}
export function useUsersList() {
  const users = useUsersMap();
  return useMemo(() => Object.values(users || {}), [users]);
}
