import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';

interface RegisteredHook {
  id: string;
  priority: number;
  onScan: (code: string) => void;
}

interface BarcodeScannerContextType {
  registerHook: (
    id: string,
    priority: number,
    onScan: (code: string) => void,
  ) => void;
  unregisterHook: (id: string) => void;
}

export const BarcodeScannerContext = createContext<BarcodeScannerContextType>({
  registerHook: () => {},
  unregisterHook: () => {},
});

export const BarcodeScannerProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [registeredHooks, setRegisteredHooks] = useState<RegisteredHook[]>([]);

  // Register a hook instance
  const registerHook = useCallback(
    (id: string, priority: number, onScan: (code: string) => void) => {
      setRegisteredHooks(prev => [...prev, { id, priority, onScan }]);
    },
    [],
  );

  const unregisterHook = useCallback((id: string) => {
    setRegisteredHooks(prev => prev.filter(hook => hook.id !== id));
  }, []);

  useEffect(() => {
    const buffer: string[] = []; // Store keypresses
    const timestamps: number[] = []; // Store timestamps of keypresses
    let timer: NodeJS.Timeout | null = null;

    const handleKeyDown = (event: KeyboardEvent) => {
      const key = event.key;
      const timestamp = performance.now();

      if (
        key.length === 1 &&
        !event.ctrlKey &&
        !event.altKey &&
        !event.metaKey
      ) {
        buffer.push(key);
        timestamps.push(timestamp);

        if (timer) {
          clearTimeout(timer);
        }

        timer = setTimeout(() => {
          processBuffer();
        }, 100);
      }
    };

    const processBuffer = () => {
      if (buffer.length >= 5) {
        const intervals = timestamps.slice(1).map((t, i) => t - timestamps[i]);
        const isScan = intervals.every(interval => interval < 30);

        if (isScan) {
          const barcode = buffer.join('');
          if (registeredHooks.length > 0) {
            const highestPriorityHook = registeredHooks.reduce(
              (prev, current) =>
                prev.priority > current.priority ? prev : current,
            );
            highestPriorityHook.onScan(barcode);
          }
        }
      }
      buffer.length = 0;
      timestamps.length = 0;
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [registeredHooks]);

  return (
    <BarcodeScannerContext.Provider value={{ registerHook, unregisterHook }}>
      {children}
    </BarcodeScannerContext.Provider>
  );
};

export const useBarcodeScannerContext = () => useContext(BarcodeScannerContext);
