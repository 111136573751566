import { MaterialList, MaterialListStatus } from '@certhon/domain-models/lib';
import { descend, prop, sortWith } from 'ramda';
import React, { useEffect } from 'react';
import { Button, Col, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Link } from 'wouter';
import matchUserToTerm from '../common/user/matchUserToTerm';
import FormattedRelative from '../components/FormattedRelative';
import msg from '../components/MaterialListDetailsEditor/msg';
import ParameterizedSearch from '../components/ParameterizedSearch';
import { parseParameters } from '../components/ParameterizedSearch/model';
import Spinner from '../components/Spinner';
import UserToken from '../components/UserToken';
import { useProjectMap, useProjects } from '../modules/projects';
import { StoreUser, useCurrentUser } from '../modules/user';
import { useUsersList, useUsersMap } from '../modules/users';
import { useMaterialListStore } from '../stores/materialListStore';
import combineFilters from '../utils/combineFilters';

const MaterialListStatuses: MaterialListStatus[] = [
  'DRAFTED',
  'SUBMITTED',
  'CANCELLED',
  'COMPLETED',
];

interface MaterialListOverviewContainerProps {}
const MaterialListOverviewContainer: React.FC<MaterialListOverviewContainerProps> = ({}) => {
  const [{ materialLists }, { fetch }] = useMaterialListStore();
  const sortedMaterialLists = React.useMemo(
    () => sortWith([descend(prop('created_at'))], Object.values(materialLists)),
    [materialLists],
  );

  const [hasFetched, setHasFetched] = React.useState<boolean>(false);
  const [err, setErr] = React.useState<Error | null>(null);
  const users = useUsersMap();
  const projectMap = useProjectMap();
  const projects = useProjects();
  const usersList = useUsersList();
  if (err) {
    throw err;
  }
  useEffect(() => {
    fetch().then(() => setHasFetched(true), setErr);
  }, [fetch]);
  function filterFromUser(user?: StoreUser) {
    return user ? `creator:"${user.recnum}"` : '';
  }

  const user = useCurrentUser();
  const [filterValue, setFilterValue] = React.useState<string>(
    filterFromUser(user),
  );
  useEffect(() => setFilterValue(filterFromUser(user)), [user]);

  const filteredMaterialLists = React.useMemo(() => {
    const { parameters, rest } = parseParameters(filterValue);
    const filters: Array<(ml: MaterialList) => boolean> = [];
    const creator = parameters.filter(({ name }) => name === 'creator');
    if (creator.length) {
      const userRecnums = creator.map(c => parseInt(c.value, 10));
      filters.push(ml => userRecnums.includes(ml.created_by_user_id));
    }
    const status = parameters.filter(({ name }) => name === 'status');
    if (status.length) {
      const statuses = status.map(c => c.value);
      filters.push(ml => statuses.includes(ml.status));
    }
    const filter = combineFilters(filters);
    // return sortedMaterialLists.filter(filter as any);

    return sortedMaterialLists.filter(filter as any);
  }, [sortedMaterialLists, filterValue]);

  const searchOptions = React.useMemo(() => {
    const userOpts = {
      options: usersList,
      search: matchUserToTerm,
      getValue: (u: any) => {
        return u.recnum.toString();
      },
      getLabel: (u: any) => {
        return u.name || u.loginname;
      },
      getLabelFromValue: (u: any) =>
        (users?.[u] && (users?.[u]?.name || users?.[u]?.loginname)) || u,
    };
    return {
      parameters: {
        creator: {
          name: 'creator',
          icon: 'user-plus',
          ...userOpts,
        },
        status: {
          name: 'status',
          icon: 'check-circle',
          options: MaterialListStatuses,
        },
      },
      small: false,
      noInput: false,
    };
  }, []);

  return !hasFetched || !users ? (
    <Spinner></Spinner>
  ) : (
    <>
      <ParameterizedSearch
        value={filterValue}
        options={searchOptions}
        onChange={setFilterValue}
        onClear={() => setFilterValue('')}
      />
      <Link to="/inventory/lists/new">
        <Button bsSize={'sm'}>
          <i className="fa fa-plus"></i> New material list
        </Button>
      </Link>
      <ListGroup>
        <ListGroupItem>
          <Row>
            <Col xs={5}>
              <small>
                <FormattedMessage {...msg.type} />
              </small>
            </Col>
            <Col xs={3}>
              <small>
                <FormattedMessage {...msg.STATUS} />
              </small>
            </Col>
            <Col xs={4}>
              <small>
                <FormattedMessage {...msg.CREATED} />
              </small>
            </Col>{' '}
          </Row>
        </ListGroupItem>
        {filteredMaterialLists.map((list, index) => (
          <Link to={`/inventory/lists/${list.recnum}`}>
            <ListGroupItem key={index}>
              <Row>
                <Col xs={5}>
                  <FormattedMessage
                    {...(msg as any)[list.order_type + '_SHORT']}
                  />
                  <small>
                    <br />{' '}
                    {(list.project_id &&
                      ` ${projectMap?.[list.project_id]?.klant_naam} / ${
                        projectMap?.[list.project_id]?.projectname
                      }`) ||
                      (list.klant_id &&
                        projects?.find(p => p.klant_id === list.klant_id)
                          ?.klant_naam)}
                  </small>
                </Col>
                <Col xs={3}>
                  <small>
                    <FormattedMessage {...msg[list.status]} />
                  </small>
                </Col>
                <Col xs={4}>
                  <FormattedRelative value={list.created_at} />{' '}
                  {/* <FormattedMessage id="BY" defaultMessage="by" />{' '} */}
                  <UserToken
                    withText
                    small
                    user={users[list.created_by_user_id]}
                  />
                </Col>{' '}
                <Col xs={4}></Col>
              </Row>
            </ListGroupItem>
          </Link>
        ))}
      </ListGroup>
    </>
  );
};
export default MaterialListOverviewContainer;
