import { MaterialOrderType } from '@certhon/domain-models/lib';
import React, {
  FormEvent,
  FormEventHandler,
  useCallback,
  useEffect,
} from 'react';
import {
  Alert,
  Button,
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Glyphicon,
  InputGroup,
  ButtonGroup,
  ListGroup,
  ListGroupItem,
  Row,
  ToggleButton,
  ToggleButtonGroup,
} from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'wouter';
import artikelCode from '../../common/logic/artikelCode';
import { MakeOptionalExcept } from '../../common/logic/types';
import { UsersState } from '../../modules/users';
import {
  MaterialList,
  MaterialListItem,
  MaterialListStoreInterface,
  UpdatableMaterialList,
} from '../../stores/materialListStore';
import ArtikelPickerModal, {
  ArtikelPickerModalRef,
} from '../ArtikelPickerModal';
import ClientOrProjectSelectionControl from '../ClientOrProjectSelectionControl/ClientOrProjectSelectionControl';
import { useContextMenu } from '../ContextMenu';
import FormattedRelative from '../FormattedRelative';
import QRScannerModal, { QRScannerModalRef } from '../QRScannerModal';
import UserToken from '../UserToken';
import msg from './msg';
import useLocations from '../../hooks/useLocations';
import { makeTreeIndex } from '../Tree/common';
import { serializeParameters } from '../ParameterizedSearch/model';
import activityIndicatorImg from '../../assets/loader.gif';
import { Stock } from '../../modules/locations';
import useBarcodeScanner from '../../services/BarcodeScanner/useBarcodeScanner';

interface MaterialListDetailsEditorProps
  extends Partial<
    Pick<
      MaterialListStoreInterface,
      'updateMaterial' | 'addMaterial' | 'removeMaterial'
    >
  > {
  error: Error | null;
  onChange: (cr: Partial<UpdatableMaterialList>, save?: boolean) => void;
  onSubmit?: (ev?: FormEvent) => void;
  onRemove?: () => void;
  users?: UsersState;
  // materialList: MaterialList;
  materialList: MakeOptionalExcept<
    MaterialList,
    | 'project_id'
    | 'klant_id'
    // | 'name'
    | 'note'
    | 'order_type'
  >;
}

function isValidMaterialList(
  materialList: Pick<MaterialList, 'order_type' | 'klant_id' | 'project_id'>,
) {
  const { order_type, project_id, klant_id /* note */ } = materialList;
  return !!(
    (
      (order_type !== 'FROM_PROJECT' && order_type !== 'TO_PROJECT') ||
      project_id ||
      klant_id
    )
    // &&(order_type !== 'SUPPLIER' || !!note)
  );
}

const orderTypes: MaterialOrderType[] = [
  'FROM_PROJECT',
  'FROM_SUPPLIER',
  'INVENTORY',
  'SCRAP',
  'TO_PROJECT',
];

const MaterialListDetailsEditor: React.FC<MaterialListDetailsEditorProps> = ({
  materialList,
  error,
  onChange,
  onRemove,
  onSubmit,
  updateMaterial,
  removeMaterial,
  addMaterial,
  users,
}) => {
  const {
    created_at,
    created_by_user_id,
    recnum,
    material,
    // name,
    order_type,
    status,
    note,
    klant_id,
    project_id,
    processed_at,
    processed_by_user_id,
    modified_at,
  } = materialList;
  const artikelPickerModalRef = React.useRef<ArtikelPickerModalRef>(null);
  const qrScannerModalRef = React.useRef<QRScannerModalRef>(null);

  const rootLocations = useLocations();
  const locationIndex = React.useMemo(() => makeTreeIndex(rootLocations), [
    rootLocations,
  ]);

  const intl = useIntl();
  /*
   * callbacks
   */
  const handleChange = useCallback<FormEventHandler<any>>(
    ev => {
      const change: Partial<UpdatableMaterialList> = {
        [ev.currentTarget.name]: ev.currentTarget.value,
      };
      onChange(change);
    },
    [onChange],
  );

  const handleChangeClientOrProject = React.useCallback(
    (change: { clientId: number | null; projectId: number | null }) => {
      onChange({ project_id: change.projectId, klant_id: change.clientId });
    },
    [onChange],
  );

  const handleSearchItem = useCallback<FormEventHandler<any>>(
    ev => {
      artikelPickerModalRef.current!.selectArtikel().then(stock => {
        if (stock && recnum) {
          addMaterial?.(recnum, [
            {
              amount: 1,
              artikel_id: stock.recnum,
              location_id: stock.location_id,
            },
          ]);
        }
      });
    },
    [addMaterial, recnum],
  );
  const handleScan = React.useCallback(
    async (result: string | null, focus: boolean = false) => {
      if (result) {
        let regResult = '';
        if (/qr\/location/.test(result)) {
          regResult = /([0-9a-f-]{36})/.exec(result)?.[1] || '';
        }
        if (!regResult) {
          alert('Invalid QR code format');
          return;
        }
        const loc = locationIndex[regResult];
        if (!loc) {
          alert('Could not find location');
          return;
        }
        let stock: Stock | null = null;
        if (loc.stock.length > 1 || !loc.stock.length) {
          const searchParams = serializeParameters({
            parameters: [{ name: 'location', value: regResult }],
            newParam: null,
            rest: '',
          });
          stock =
            (await artikelPickerModalRef.current?.selectArtikel(
              searchParams,
            )) || null;
        } else {
          stock = loc.stock[0];
        }
        if (stock && recnum) {
          const existingMaterial = material?.find(
            m => m.artikel_id === stock?.recnum,
          );
          let isDone: Promise<any> = Promise.resolve();
          if (existingMaterial) {
            isDone = updateMaterial!(recnum, [
              {
                recnum: existingMaterial.recnum,
                amount: existingMaterial.amount + 1,
              },
            ]);
          } else {
            isDone = addMaterial!(recnum, [
              {
                amount: 1,
                artikel_id: stock.recnum,
                location_id: stock.location_id,
              },
            ]);
          }
          focus &&
            isDone.then(() => {
              setTimeout(() => {
                const input = document.querySelector(
                  '.last-item input',
                ) as HTMLInputElement;
                if (!input) {
                  return;
                }
                input.focus();
                input.select();
              }, 500);
            });
        }
      }
    },
    [addMaterial, locationIndex, material, recnum, updateMaterial],
  );

  useBarcodeScanner(handleScan, 10);

  const handleStartScan = useCallback<FormEventHandler<any>>(
    ev => {
      qrScannerModalRef.current
        ?.scanQRCode()
        .then(code => handleScan(code, true));
    },
    [addMaterial, recnum, locationIndex],
  );

  return (
    <div className="container">
      <form
        onSubmit={ev => {
          ev.preventDefault();
          onSubmit?.(ev);
        }}
      >
        {error && <Alert bsStyle="danger">{error.message}</Alert>}
        {/* <Row>
          <Col xs={12}>
            <FormGroup>
              <ControlLabel>
                <FormattedMessage {...msg.NAME} />
              </ControlLabel>
              <FormControl
                type="text"
                disabled={status !== 'DRAFTED'}
                onChange={handleChange}
                value={name || ''}
                name="name"
                placeholder={intl.formatMessage(msg.NAME)}
              />
            </FormGroup>
          </Col>
        </Row> */}
        <Row>
          <Col xs={12}>
            <FormGroup>
              <ControlLabel>
                <FormattedMessage {...msg.ORDER_TYPE} />{' '}
              </ControlLabel>{' '}
              <Link to="/docs/10-Magazijn-boekingen#boekingstype">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a>
                  <i className="fa fa-info-circle"></i>
                </a>
              </Link>
              <FormControl
                disabled={status !== 'DRAFTED'}
                componentClass="select"
                value={order_type}
                name="order_type"
                onChange={handleChange}
              >
                {orderTypes.map(t => (
                  <option key={t} value={t}>
                    <FormattedMessage {...msg[t]} />
                  </option>
                ))}
              </FormControl>
            </FormGroup>
          </Col>
        </Row>
        {(order_type === 'TO_PROJECT' || order_type === 'FROM_PROJECT') && (
          <ClientOrProjectSelectionControl
            clientId={klant_id || null}
            projectId={project_id || null}
            disabled={status !== 'DRAFTED'}
            onChange={handleChangeClientOrProject}
          />
        )}
        <Row>
          <Col xs={12}>
            <FormGroup>
              <ControlLabel>
                <FormattedMessage {...msg.note} />
              </ControlLabel>
              <FormControl
                componentClass="textarea"
                onChange={handleChange}
                disabled={status !== 'DRAFTED'}
                value={note || ''}
                name="note"
                placeholder={
                  order_type !== 'FROM_SUPPLIER'
                    ? intl.formatMessage(msg.notePlaceholder)
                    : intl.formatMessage(msg.notePlaceholderSupplier)
                }
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <FormGroup>
              <ControlLabel>
                <FormattedMessage {...msg.CREATED_BY} />
              </ControlLabel>
              <div>
                {created_by_user_id && users![created_by_user_id] ? (
                  <UserToken small withText user={users![created_by_user_id]} />
                ) : (
                  <p className="form-control-static">
                    <FormattedMessage {...msg.UNKNOWN_USER} />
                  </p>
                )}
              </div>
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <ControlLabel>
                <FormattedMessage {...msg.CREATED} />
              </ControlLabel>
              <div>
                {created_at ? (
                  <FormattedRelative value={created_at} />
                ) : (
                  <p className="form-control-static">-</p>
                )}
              </div>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <FormGroup>
              <ControlLabel>
                <FormattedMessage {...msg.STATUS} />{' '}
                <Link to="/docs/10-Magazijn-boekingen#status">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a>
                    <i className="fa fa-info-circle"></i>
                  </a>
                </Link>
              </ControlLabel>
              {status === 'DRAFTED' ? (
                <InputGroup>
                  <FormControl
                    type="text"
                    disabled
                    value={intl.formatMessage(msg.DRAFTED)}
                  />
                  <InputGroup.Button>
                    <Button
                      disabled={!isValidMaterialList(materialList)}
                      onClick={() => {
                        onChange({ status: 'SUBMITTED' }, true);
                      }}
                      bsStyle="primary"
                    >
                      <FormattedMessage
                        {...msg.SUBMIT_FOR_PROCESSING_BUTTON}
                      ></FormattedMessage>{' '}
                      &rarr;
                    </Button>
                  </InputGroup.Button>
                </InputGroup>
              ) : status === 'SUBMITTED' ? (
                <InputGroup>
                  <FormControl
                    type="text"
                    value={intl.formatMessage(msg.SUBMITTED)}
                    disabled
                  />
                  <InputGroup.Button>
                    <Button
                      onClick={() => {
                        onChange({ status: 'DRAFTED' }, true);
                      }}
                      bsStyle="primary"
                    >
                      &larr; <FormattedMessage {...msg.REVERT_TO_DRAFT} />
                    </Button>
                  </InputGroup.Button>
                </InputGroup>
              ) : (
                <FormControl
                  type="text"
                  value={intl.formatMessage(msg[status!])}
                  disabled
                />
              )}
            </FormGroup>
          </Col>
        </Row>
        {/* <Row>
          <Col xs={6}>
            <FormGroup>
              <ControlLabel>Processed by</ControlLabel>
              <div>
                {processed_by_user_id && users![processed_by_user_id] ? (
                  <UserToken
                    small
                    withText
                    user={users![processed_by_user_id]}
                  />
                ) : (
                  <p className="form-control-static">Unknown user</p>
                )}
              </div>
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <ControlLabel>Processed at</ControlLabel>
              <p className="form-control-static">
                {processed_at ? (
                  <FormattedRelative value={processed_at} />
                ) : (
                  '-'
                )}
              </p>
            </FormGroup>
          </Col>
        </Row> */}

        <Row>
          <Col xs={12}>
            <Button disabled={!onSubmit || status !== 'DRAFTED'} type="submit">
              <FormattedMessage {...msg.SAVE} />
            </Button>
            {onRemove && (
              <Button
                type="button"
                disabled={status !== 'DRAFTED'}
                className="pull-right btn-link danger"
                onClick={() => {
                  const confirmMessage = intl.formatMessage(msg.CONFIRM_DELETE);
                  // eslint-disable-next-line no-restricted-globals
                  if (confirm(confirmMessage)) {
                    onRemove();
                  }
                }}
              >
                <FormattedMessage {...msg.DELETE} />
              </Button>
            )}
          </Col>
        </Row>
      </form>
      {recnum && (
        <Row>
          <hr />
          <Col xs={12}>
            <FormGroup>
              <ControlLabel>Material</ControlLabel>
              <ListGroup>
                <ListGroupItem>
                  <Row>
                    <Col xs={2}>
                      <FormattedMessage {...msg.CODE} />
                    </Col>
                    <Col xs={6}>
                      <FormattedMessage {...msg.DESCRIPTION} />
                    </Col>
                    <Col xs={3}>
                      <FormattedMessage {...msg.AMOUNT} />
                    </Col>
                  </Row>
                </ListGroupItem>
                {material &&
                  material.map((item, index) => (
                    <MaterialListItemDisplay
                      key={index}
                      item={item}
                      last={index === material.length - 1}
                      disabled={status !== 'DRAFTED'}
                      removeMaterial={removeMaterial!}
                      updateMaterial={updateMaterial!}
                    />
                  ))}
                <ListGroupItem>
                  <Row>
                    <form action="" className="form-inline pull-right">
                      <FormGroup>
                        <ControlLabel>
                          <FormattedMessage {...msg.ADD_MATERIAL} />
                        </ControlLabel>{' '}
                        <InputGroup>
                          <ButtonGroup className="pull-right">
                            <Button
                              disabled={
                                status !== 'DRAFTED' || !rootLocations.length
                              }
                              onClick={handleStartScan}
                            >
                              Scannen{' '}
                              {rootLocations.length ? (
                                <i className="fa fa-qrcode"></i>
                              ) : (
                                <img
                                  alt="activity indicator"
                                  src={activityIndicatorImg}
                                />
                              )}
                            </Button>
                            <Button
                              // bsStyle={'secondary'}
                              disabled={status !== 'DRAFTED'}
                              onClick={handleSearchItem}
                            >
                              Zoeken <i className="fa fa-search"></i>
                            </Button>
                          </ButtonGroup>
                        </InputGroup>
                      </FormGroup>
                    </form>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </FormGroup>
          </Col>
        </Row>
      )}
      <ArtikelPickerModal ref={artikelPickerModalRef} />
      <QRScannerModal ref={qrScannerModalRef} />
    </div>
  );
};

export default MaterialListDetailsEditor;

type MaterialListItemProps = {
  item: MaterialListItem;
  disabled?: boolean;
  last: boolean;
} & Pick<MaterialListStoreInterface, 'updateMaterial' | 'removeMaterial'>;

const MaterialListItemDisplay: React.FC<MaterialListItemProps> = ({
  item,
  disabled = false,
  removeMaterial,
  last,
  updateMaterial,
}) => {
  const amountInputRef = React.useRef<HTMLInputElement>(null);
  const intl = useIntl();
  const { node, onContextMenuHandler } = useContextMenu([
    {
      type: 'action',
      label: intl.formatMessage(msg.DELETE_ITEM),
      className: 'danger',
      action: () => removeMaterial?.(item.material_list_id, [item.recnum]),
    },
  ]);
  const LGI = ListGroupItem as any;

  useEffect(() => {
    if (amountInputRef.current) {
      amountInputRef.current.value = item.amount.toString();
    }
  }, [item]);

  return (
    <LGI
      onContextMenu={disabled ? undefined : onContextMenuHandler}
      className={last ? 'last-item' : null}
    >
      <Row>
        <Col xs={2}>{artikelCode(item.artikel)}</Col>
        <Col xs={6}>{item.artikel.description}</Col>
        <Col xs={3}>
          <form
            onSubmit={e => {
              e.preventDefault();
            }}
          >
            <FormControl
              inputRef={amountInputRef as any}
              disabled={disabled}
              bsSize="sm"
              type="number"
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  (e.currentTarget as any).blur();
                }
              }}
              data-item-recnum={item.recnum}
              onBlur={ev => {
                const { value } = (ev.currentTarget as any) as HTMLInputElement;
                const amount = parseFloat(value);
                if (!isNaN(amount)) {
                  updateMaterial(item.material_list_id, [
                    { recnum: item.recnum, amount },
                  ]);
                }
              }}
            />
          </form>
        </Col>
        <Col xs={1}>
          {!disabled && (
            <Button
              style={{
                marginRight: -16,
              }}
              // bsSize="sm"
              bsStyle={'link'}
              className="pull-right"
              onClick={onContextMenuHandler as any}
            >
              <Glyphicon glyph="option-vertical" />
            </Button>
          )}
        </Col>
      </Row>
      {node}
    </LGI>
  );
};
