import { useEffect, useState } from 'react';
import { useBarcodeScannerContext } from './BarcodeScannerContext';

function useBarcodeScanner(onScan: (code: string) => void, priority: number) {
  const [hookId] = useState(() =>
    Math.random()
      .toString(36)
      .substring(7),
  );

  const { registerHook, unregisterHook } = useBarcodeScannerContext();

  useEffect(() => {
    registerHook(hookId, priority, onScan);
    return () => {
      unregisterHook(hookId);
    };
  }, [hookId, priority, onScan, registerHook, unregisterHook]);
}

export default useBarcodeScanner;
