import regEscape from 'escape-string-regexp';
import { prop } from 'lodash/fp';
import complement from 'ramda/es/complement';
import { parseParameters } from '../../components/ParameterizedSearch/model';
import { overlaps } from '../../utils';
import combineFilters from '../../utils/combineFilters';
import departmentToChar from './departmentToChar';
import { isListClosed, isListOverdue } from './list';
import {
  getTicketUsers,
  isProjectTicket,
  isTicketClosed,
  isTicketOverdue,
} from './ticket';

/**
 * Creates a set of filters to filter entities:
 *  - tickets
 *  - lists
 *  - meetings
 */
export default function createFilters(search, projectWorks) {
  const { parameters, rest } = parseParameters(search);

  let meetingFilters = [];
  let ticketFilters = [];
  let listFilters = [];

  const works = parameters.filter(({ name }) => name === 'work');
  if (works.length) {
    const workRecnums = works.map(w => parseInt(w.value, 10));
    listFilters.push(l => workRecnums.includes(l.werk_id));
    ticketFilters.push(t => workRecnums.includes(t.werk_id));
  }

  const overdue = parameters.find(({ name }) => name === 'overdue');
  if (overdue) {
    const invert = overdue.value !== 'true';
    ticketFilters.push(invert ? complement(isTicketOverdue) : isTicketOverdue);
    listFilters.push(invert ? complement(isListOverdue) : isListOverdue);
  }

  // DEPARTMENT
  const departments = parameters
    .filter(({ name }) => name === 'department')
    .map(prop('value'))
    .map(departmentToChar);
  if (departments.length) {
    const workIds = projectWorks
      .filter(w => departments.includes(w.afd.toUpperCase()))
      .map(w => w.recnum);
    ticketFilters.push(t => workIds.includes(t.werk_id));
  }

  // projectTicketsOnly, only project tickets
  const projectTickets = parameters.find(
    ({ name }) => name === 'projectTickets',
  );
  if (projectTickets) {
    const invert = projectTickets.value !== 'true';
    ticketFilters.push(invert ? complement(isProjectTicket) : isProjectTicket);
  }
  // STATUS, only single status is supported
  const status = parameters.find(({ name }) => name === 'status');
  if (status) {
    const closed = status.value === 'closed';
    ticketFilters.push(closed ? isTicketClosed : complement(isTicketClosed));
    listFilters.push(closed ? isListClosed : complement(isListClosed));
  }
  // ASSIGNED USER, multiple users are supported
  const assigned = parameters.filter(({ name }) => name === 'assigned');
  if (assigned.length) {
    const userRecnums = assigned.map(a => parseInt(a.value, 10));

    listFilters.push(l => overlaps(l.users, userRecnums));

    ticketFilters.push(t => userRecnums.includes(t.assignee_user_id));
    meetingFilters.push(m =>
      overlaps([...m.present, ...m.absent], userRecnums),
    );
  }
  // EXECUTOR USER, multiple users are supported
  const executor = parameters.filter(({ name }) => name === 'performedBy');
  if (executor.length) {
    const userRecnums = executor.map(a => parseInt(a.value, 10));
    ticketFilters.push(t => userRecnums.includes(t.executor_user_id));
  }
  // CREATOR USER, multiple users are supported
  const creator = parameters.filter(({ name }) => name === 'creator');
  if (creator.length) {
    const userRecnums = creator.map(c => parseInt(c.value, 10));
    listFilters.push(l => userRecnums.includes(l.creator_user_id));
    ticketFilters.push(t => userRecnums.includes(t.creator_user_id));
    meetingFilters.push(m => userRecnums.includes(m.creator));
  }
  // ASSOCIATED USER, multiple users are supported
  // NOTE: the implementation 'conflates' with assigned AND creator
  const associated = parameters.filter(({ name }) => name === 'associated');
  if (associated.length) {
    const userRecnums = associated.map(c => parseInt(c.value, 10));
    listFilters.push(l => overlaps(userRecnums, l.users));
    ticketFilters.push(t => overlaps(userRecnums, getTicketUsers(t)));
    meetingFilters.push(m =>
      overlaps([...m.present, ...m.absent], userRecnums),
    );
  }
  const labels = parameters.filter(({ name }) => name === 'label');
  if (labels.length) {
    const values = labels.map(({ value }) => parseInt(value, 10));

    ticketFilters.push(t => overlaps(values, t.labels));
  }

  if (rest) {
    let regex = new RegExp(regEscape(rest), 'i');
    const number = Number(rest);

    ticketFilters.push(
      t =>
        regex.test(t.title) || regex.test(t.description) || t.number === number,
    );
    listFilters.push(l => regex.test(l.name));
    meetingFilters.push(
      m =>
        regex.test(m.name) ||
        m.sections.some(s => s.content.some(c => regex.test(c))),
    );
  }

  let meetingFilter = combineFilters(meetingFilters);
  let ticketFilter = combineFilters(ticketFilters);
  let listFilter = combineFilters(listFilters);

  return [ticketFilter, listFilter, meetingFilter];
}
